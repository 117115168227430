<template>
 <div class="list-group">
  <!--
  <a href="#" class="list-group-item list-group-item-action flex-column align-items-start ">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">List group item heading</h5>
      <small>3 days ago</small>
    </div>
    <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
    <small>Donec id elit non mi porta.</small>
  </a>
 -->

   <a v-for="(item,imageIndex) in option_input" :key="imageIndex" href="#" class="list-group-item list-group-item-action flex-column align-items-start">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">{{item.label}}</h5>
    
    </div>
    <p class="mb-1">€ {{item.value}}</p>
  
  </a>  


</div>     
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from 'axios';

export default {
 

  data() {
    return {
     
      options_data_selected: [],
     
   
    };
  },

  props: {

    option_input:{
      type: Array,
      default: () => [],   
    },
   

    type:{
      type: String,
      default: "",   
    },

    
    

  },

  



  watch: {
      option_selected_input:function(){
          
          var self=this;
          this.options_data_selected=this.option_selected_input;
       
        },
  },


  

  methods: {

    

    

    

    

  },

  created(){

   
    this.options_data_selected=this. option_selected_input;
   

  }
}; // export
</script>

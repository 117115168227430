<template>
  <div>
    <multiselect
      id="ajax"
      v-model="selected"
      label="nome"
      track-by="id"
      placeholder="Operatore"
      :options="options_data"
      selectLabel="Invio per selezionare"
      :multiple="true"
      :searchable="true"
      :loading="isLoading"
      :internal-search="true"
      :clear-on-select="true"
      :close-on-select="true"
      :options_data-limit="3"
      :limit="10"
      :limit-text="limitText"
      :max-height="2000"
      :show-no-results="false"
      :hide-selected="false"
      :preserve-search="true"
      @input="notify"
      @search-change="searchByString"
    >
      <span slot="noResult">{{ $t("message.not_found_search") }}</span>
      <span slot="noOptions">Non ci sono risultati disponibili</span>
    </multiselect>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";

var LimitFunction = require("../../src/limitFunction").default;

export default {
  components: { Multiselect },

  data() {
    return {
      selected: null,
      options_data: [],
      isLoading: false,
    };
  },

  props: ["value", "operatore"],
  watch: {
    value: function (v2, v1) {
      if (v2 !== this.selected) {
        this.updateCombo();
        this.selected = v2;
      }
    },
    operatore: function (v2, v1) {
      this.updateCombo();
    },
  },

  methods: {
    apiCall: function (params, callback) {
      let self = this;
      LimitFunction(function () {
        self.isLoading = true;
        axios({
          method: "get",
          url: self.$url + "operatori/combo",
          params: params,
          headers: self.$config.axiosHeaders(),
        })
          .then(function (response) {
            callback(response.data, params);
            self.isLoading = false;
          })
          .catch(function (error) {
            self.isLoading = false;
            console.log(error);
          });
      }, 300);
    },

    updateCombo: function () {
      this.selected = this.value;
      let self = this;
      if (this.selected.length > 0) {
        this.apiCall({ id: this.selected }, function (data, params) {
          self.populateComboBox(data, params);
          self.selected = self.options_data[0];
        });
        return;
      }
    },

    notify: function (value, id) {
      this.selected_id = this.selected.id;

      this.$emit("input", this.selected);
      this.$emit("operatore", this.selected);
    },

    limitText: function (count) {
      return `e ${count} altre destinazioni`;
    },

    populateComboBox: function (elements, params) {
      this.$set(this, "options_data", elements);
    },
    searchByString: function (itemToSearch) {
      //servono almeno 3 caratteri per avviare la ricerca
      let params = {};
      let self = this;

      if (itemToSearch && itemToSearch.length >= 2) {
        params.name = itemToSearch;

        if (self.provincia > 0) {
          params.provincia = self.provincia;
        }

        this.apiCall(params, function (data, params) {
          self.populateComboBox(data, params);
        });
      }
    },
  },

  created() {
    this.selected = this.value || [];
    this.options_data = JSON.parse(JSON.stringify(this.selected)) ;
  },
};
</script>

<style scoped>
</style>

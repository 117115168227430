let menu = async function (api) {
  let interessi_menu = [
    {
      href: "/category-list/5",
      title: "Modifica Categorie",
      abilities: "category-view",
    },
  ];

  if (await api.user.can("interessi-view")) {
    let interessi = await api.categories.index(5);

    for (let interesse of interessi.data.children) {
      interessi_menu.push({
        href:
          "/interessi-list/" +
          interesse.id +
          "/" +
          interesse.contents.it.title,
        title: interesse.contents.it.title,
        icon: "fa fa-list",
        abilities: "interessi-view",
      });
    }
  }

  const simpleSection = (item, others = []) => {
    return {
      title: item.title,
      icon: item.icon,
      abilities: (item.ability || 'event') + "-view",
      child: [
        {
          href: item.href,
          title: "Elenco",
          icon: "fa fa-list",
          abilities: (item.ability || 'event') + "-view",
        },
        {
          href: item.href + "/new",
          title: "Nuovo",
          icon: "fa fa-plus",
          abilities: (item.ability || 'event') + "-create",
        },
        item.category ? {
          href: "/category-list/" + item.category,
          title: "Categorie",
          abilities: "category-view",
        } : null,
        ...others
      ].filter((itm) => itm !== null)
    }
  }

  const luoghi = () => {
    return simpleSection({
      title: "LUOGHI", 
      href: "/places", 
      icon: "fa fa-map-marker", 
      category: 2, 
      ability: 'place'
    }, [
      {
        href: "/category-list/3",
        title: "Tipologia proprietà",
        abilities: "category-view",
      },
      {
        href: "/category-list/4",
        title: "Tipologia gestione",
        abilities: "category-view",
      },
      {
        href: "/category-list/6",
        title: "Target",
        abilities: "category-view",
      },
      {
        href: "/category-list/7",
        title: "Accessibilità",
        abilities: "category-view",
      }
    ]);
  };

  const applicazione = () => {
    return {
      title: "CONTENUTI APP",
      icon: "fa fa-mobile",
      abilities: "create-user",
      child:
        [
          { title: "Ristoranti", href: "/restaurants", icon: "fa  fa-cutlery", category: 9 },
          { title: "Guide", href: "/guides", icon: "ti-info-alt", category: 13 },
          { title: "Rent", href: "/rent-a-car", icon: "fa fa-question", category: 14 },
          { title: "Sport e Benessere", href: "/sport-wellness", icon: "fa fa-child", category: 8 },
          { title: "Station", href: "/stations", icon: "fa fa-car", category: 0 },
          { title: "Hotel", href: "/stay", icon: "fa fa-bed", category: 12 },
        ].map((item) => {
          return {
            title: item.title,
            icon: item.icon,
            child: [
              {
                href: item.href,
                title: "Elenco",
                icon: "fa fa-list",
                abilities: "event-view",
              },
              {
                href: item.href + "/new",
                title: "Nuovo",
                icon: "fa fa-plus",
                abilities: "event-create",
              },
              item.category ? {
                href: "/category-list/" + item.category,
                title: "Categorie",
                abilities: "category-view",
              } : null,
            ].filter((itm) => itm !== null)
          }
        })
    };
  };

  const events = () => {
    return {
      title: "EVENTI",
      icon: "fa fa-calendar",
      abilities: "event-view",
      child: [
        {
          href: "/events",
          title: "Lista Eventi",
          icon: "fa fa-list",
          abilities: "event-view",
        },
        {
          href: "/events/new",
          title: "Nuovo Evento",
          icon: "fa fa-plus",
          abilities: "event-create",
        },
        {
          href: "/category-list/1",
          title: "Categorie Eventi",
          abilities: "category-view",
        },
      ],
    };
  };

  const blog = () => {
    return {
      title: "NEWS",
      icon: "fa fa-newspaper-o",
      abilities: "blog-view",
      child: [
        {
          href: "/blog",
          title: "Lista Articoli",
          icon: "fa fa-list",
          abilities: "blog-view",

        },
        {
          href: "/blog/new",
          title: "Nuovo Articolo",
          icon: "fa fa-plus",
          abilities: "blog-create"
        },
        {
          href: "/category-list/15",
          title: "Categorie",
          abilities: "category-view",
        }
      ],
    };
  };

  const stats = () => {
    return {
      title: "TURISMO",
      icon: "fa fa-signal",
      abilities: true,
      child: [
        {
          href: "/stats/attendances",
          title: "Presenza online",
          abilities: true
        },
        {
          href: "/stats/services",
          title: "Servizi",
          abilities: true
        },
        {
          href: "/stats/rates",
          title: "Valutazioni",
          abilities: true
        },
        {
          href: "/stats/searches",
          title: "Ricerche online",
          abilities: true
        }
      ],
    };
  };

  const tags = () => {
    return {
      href: "/tags",
      title: "TAGS",
      icon: "fa fa-tags",
      abilities: "category-create",
    };
  }

  const iat = () => {
    return {
      title: "IAT",
      icon: "fa fa-info-circle",
      abilities: "iat-view",

      child: [
        {
          href: "/iat",
          title: "Lista IAT",
          icon: "fa fa-list",
          abilities: "iat-view",
        },
        {
          href: "/iat/new",
          title: "Nuovo IAT",
          icon: "fa fa-plus",
          abilities: "iat-create",
        },
      ],
    };
  }

  const itinerary = () => {
    return {
      title: "ITINERARI",
      icon: "fa fa-list",
      abilities: "itinerary-view",

      child: [
        {
          href: "/itinerary",
          title: "Lista Itinerari",
          icon: "fa fa-list",
          abilities: "itinerary-view",
        },
        {
          href: "/itinerary/new",
          title: "Nuovo Itinerario",
          icon: "fa fa-plus",
          abilities: "itinerary-create",
        },
        {
          href: "/category-list/11",
          title: "Categorie",
          abilities: "category-view",
        },
        {
          href: "/category-list/16",
          title: "Stagionalità",
          abilities: "category-view",
        },
        {
          href: "/category-list/6",
          title: "Target",
          abilities: "category-view",
        },
        {
          href: "/category-list/4",
          title: "Durata Itinerari",
          abilities: "category-view",
        }
      ],
    };
  }

  const destinations = () => {
    return {
      title: "DESTINAZIONI",
      icon: "fa fa-globe",
      abilities: "destination-view",

      child: [
        {
          href: "/destinations",
          title: "Lista Destinazioni",
          icon: "fa fa-list",
          abilities: "destination-view",
        },
        {
          href: "/destinations/new",
          title: "Nuova Destinazioni",
          icon: "fa fa-plus",
          abilities: "destination-create",
        },
        {
          href: "/category-list/10",
          title: "Categorie",
          abilities: "category-view",
        }
      ],
    }
  };

  const interessi = () => {
    return {
      title: "INTERESSI",
      icon: "fa fa-lightbulb-o",
      abilities: "interessi-view",
      child: interessi_menu,
    };
  }

  const tematiche = () => {
    return {
      title: "TEMATICHE",
      icon: "fa fa-lightbulb-o",
      href: "/category-list/5",
      abilities: "category-view",
    };
  }

  const download = () => {

    return {
      title: "BROCHURES",
      icon: "fa fa-download",
      abilities: "download-view",

      child: [
        {
          href: "/download-list",
          title: "Lista Download",
          icon: "fa fa-list",
          abilities: "download-view",
        },
        {
          href: "/download/new/download",
          title: "Nuovo Download",
          icon: "fa fa-plus",
          abilities: "download-create",
        },
      ]
    };

  }

  const immagini = () => {

    return {
      href: "/gallery-frame-entity/true",
      title: "IMMAGINI",
      icon: "fa fa-picture-o",
      abilities: "media-create",
    };

  }

  const licenze = () => {

    return {
      href: "/licenze",
      title: "LICENZE",
      icon: "fa fa-picture-o",
      abilities: "media-create",
    };

  }

  const files = () => {
    return {
      href: "/allegati",
      title: "FILES",
      icon: "fa fa-file-o",
      abilities: "attachments-create",
    };
  }

  const pagine = () => {

    return {
      title: "PAGINE",
      icon: "fa fa-file-text-o",
      abilities: "page-create", //SOLO AMMINISTRATORE

      child: [
        {
          href: "/page",
          title: "Lista",
          icon: "fa fa-file-text-o",
          abilities: "page-create", //SOLO AMMINISTRATORE
        },
        {
          href: "/page/new",
          title: "Nuova pagina",
          icon: "fa fa-plus",
          abilities: "page-create"
        },
      ],
    };

  }

  const webcam = () => {
    return {
      title: "WEBCAM",
      icon: "fa fa-video-camera",
      abilities: "webcam-view",

      child: [
        {
          href: "/webcam-list",
          title: "Lista Webcam",
          icon: "fa fa-list",
          abilities: "webcam-view",
        },

        {
          href: "/webcam/new/webcam",
          title: "Nuova Webcam",
          icon: "fa fa-plus",
          abilities: "webcam-create",
        },
      ],
    };

  }

  const templates = () => {

    return {
      title: "TEMPLATES",
      icon: "fa fa-file-text-o",
      abilities: "user-create", //SOLO AMMINISTRATORE

      child: [
        {
          href: "/templates",
          title: "Templates",
          icon: "fa fa-file-text-o",
          abilities: "user-create", //SOLO AMMINISTRATORE
        },
        {
          href: "/templates/new",
          title: "Nuovo template",
          icon: "fa fa-plus",
          abilities: "user-create"
        },
      ],
    };

  }

  const impostazioni = () => {
    return {
      title: "IMPOSTAZIONI",
      icon: "fa fa-cog",
      abilities: "homesettings-create",
      child: [
        {
          href: "/category-list/0",
          title: "Categorie",
          icon: "fa fa-list",
          abilities: "category-view",
        },
        {
          href: "/home-settings",
          title: "Home",
          icon: "fa fa-list",
          abilities: "homesettings-create",
        },
        {
          href: "/home-settings-extra",
          title: "Extra",
          icon: "fa fa-list",
          abilities: "homesettings-create",
        },
        {
          href: "/error404-list",
          title: "Errori 404",
          icon: "fa fa-list",
          abilities: "user-create",
        },
        templates(),
        {
          title: "UTENTI",
          icon: "ti-user",
          abilities: "user-view",

          child: [
            {
              href: "/users",
              title: "Lista Utenti",
              icon: "fa fa-list",

              abilities: "user-view",
              child: [
                {
                  href: "/users/new",
                  title: "Nuovo Utente",
                  icon: "fa fa-plus",
                  abilities: "user-view",
                },
              ],
            },
            {
              href: "/users/roles",
              title: "Ruoli",
              icon: "ti-ruler",
              abilities: "user-view",
            },
            {
              href: "/users/redazioni",
              title: "Redazioni",
              icon: "ti-ruler",
              abilities: "user-view",
            },
          ],
        },
      ],
    };
  }

  const profile = () => {
    return {
      title: "PROFILO",
      icon: "fa fa-user",
      abilities: true,
      child: [
        {
          href: "/user/profile",
          title: "Profilo Utente",
          abilities: true,
          icon: "fa fa-cogs",
        },
        {
          href: "/user/logout",
          title: "Logout",
          icon: "fa fa-sign-out",
          abilities: true,
        },
      ],
    };
  }

  return [

    stats(),
    pagine(),
    blog(),
    // destinations(),
    // events(),
    luoghi(),
    // itinerary(),

    // interessi(),
    // tags(),
    // iat(),

    // download(),
    immagini(),
    licenze(),
    files(),
    // webcam(),

    // applicazione(),
    // tematiche(),
    impostazioni(),
    profile(),
  ];
}

export { menu };
export default menu;

const menuBuilder = require("./js/menu").default;
const routes = require("./js/routes").default;
const branding = require("./js/branding").default;

export {
    routes,
    branding,
    menuBuilder
};


export default {
    routes,
    branding,
    menuBuilder
};